<template>
  <div
    v-shortkey="['shift', 'esc']"
    class="footer--outer"
    @shortkey="loginOrToggleUI"
  >
    <!-- TODO (ESS) : make this location slug dynamic somehow (location summary from site) -->
    <WithLocation
      v-slot="{ isLocationLoading, theLocation }"
      slug="blue-oak-bbq"
    >
      <BasePageSection
        :class="['footer', 'py-0']"
        bg-color="#1a1d48"
        title=""
        :pa-0="['xs', 'sm'].includes($mq)"
        full-bleed-bg
      >
        <VLayout>
          <VContainer class="locations pa-0" fluid grid-list-md>
            <VLayout justify-center align-space-around row wrap fill-height>
              <VFlex sm4 xs12>
                <BaseThemeComponent
                  v-if="!isLocationLoading"
                  name="FooterLocation"
                  :location="theLocation"
                />
              </VFlex>
              <VFlex
                :class="['right-col text-md-right']"
                grow
                sm8
                text-sm-right
                text-xs-center
                xs12
              >
                <BaseThemeComponent
                  v-if="!isLocationLoading"
                  name="FooterMenu"
                  :location="theLocation"
                />
              </VFlex>
            </VLayout>
            <VLayout row wrap>
              <VFlex xs12 sm8 md8 text-xs-center text-sm-left>
                <BaseButtonSendMessage class="ml-0" color="bloak-pink" />
                <BaseButtonOutline
                  href="https://cmo.gogoguest.com/h/j/D5D86B3F13CDB60D"
                  target="_blank"
                  >Get the Newsletter</BaseButtonOutline
                >
              </VFlex>
              <VFlex
                text-xs-center
                text-sm-right
                xs12
                sm4
                md4
                white--text
                mt-4
                :pt-2="['xs', 'sm'].includes($mq)"
              >
                <span
                  >&copy; {{ new Date().getFullYear() }}
                  {{ getLegalName }}</span
                >
              </VFlex>
            </VLayout>
          </VContainer>
        </VLayout>
        <BaseRestoByline link-color="#f74b8a" />
      </BasePageSection>
    </WithLocation>
    <BaseModalsAndSnackbars email-header-class="bloak-light-blue white--text" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BlueOakFooter',
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters('site', ['getLegalName']),
  },
  methods: {
    ...mapActions('auth', ['loginOrToggleUI']),
  }, // methods
}
</script>

<style lang="scss">
.footer .section-content > .container {
  width: 100%;
  max-width: 1440px;
}
.footer-contact-btn {
  max-width: 100%;
}
</style>
